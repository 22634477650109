
@font-face {
    font-family: 'Open Sans';
    src: url('./opensans/OpenSans-ExtraBold.woff2') format('woff2'),
        url('./opensans/OpenSans-ExtraBold.woff') format('woff'),
        url('./opensans/OpenSans-ExtraBold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('./opensans/OpenSans-Bold.woff2') format('woff2'),
        url('./opensans/OpenSans-Bold.woff') format('woff'),
        url('./opensans/OpenSans-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Open Sans';
    src: url('./opensans/OpenSans-Medium.woff2') format('woff2'),
        url('./opensans/OpenSans-Medium.woff') format('woff'),
        url('./opensans/OpenSans-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('./opensans/OpenSans-Regular.woff2') format('woff2'),
        url('./opensans/OpenSans-Regular.woff') format('woff'),
        url('./opensans/OpenSans-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

* {
  box-sizing: border-box;
}    
body{
    height: 100vh;
    overflow: hidden;
    margin: 0;
    padding: 0;
} 
.calltoaction_block9{
    height: 100vh;
    overflow: hidden;
    position: relative;
}
.logoLanding{
    position: absolute;
    right: 30px;
    top: 30px;
    z-index: 999;
}
.profile-pic img{
  width: 100%;
  height: 100%;
  object-fit: cover;
      object-position: left;
} 
.container1{
    position: absolute;
    top: 0;
    bottom: 0;
    right: 10%;
    margin: auto;
    display: flex;
    align-items: center; 
    flex-wrap: wrap;
    justify-content: center;
    max-width: 743px;
}

.cover_content_bx{
    background: rgba(255, 255, 255, 0.97);
border-radius: 10px;
padding: 80px 95px;
width: 100%;
} 

.cover_content_bx .headingH3{
font-family: 'Open Sans';
font-style: normal;
font-weight: 700;
font-size: 1.6vw;
line-height: 135%;
text-align: right;
color: #222222;
margin-top: 0;
margin-bottom: 20px;
}
.cover_content_bx p.paragraph_p3 {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 1vw;
    line-height: 135.5%;
    text-align: right;
    color: #0F1700;
    margin-top: 0;
}
.cover_content_bx textarea{
    background: rgba(105, 106, 109, 0.12);
    border-radius: 4px;
    height: 199px;
    padding: 20px;
    font-family: 'Open Sans';
font-weight: 400;
font-size: 20px;
line-height: 150%;
text-align: right;
color: #0F1700;
width: 100%;
resize: none;
border:none;
}
.cover_content_bx .btn-primary{
    background: linear-gradient(268.48deg, #3956F1 0%, #00B2FF 100%);
border-radius: 10px;
padding: 29px 83px;
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
text-transform: capitalize;

color: #FFFFFF;
font-family: 'Open Sans';
font-style: normal;
font-weight: 700;
font-size: 32px;
width: 100%;
border: none;
margin: 20px 0;
cursor: pointer;
}

.bannerCaptionss{
    margin: 40px 0 0px;
    padding: 20px 0 0;
    border-top: 1px solid rgba(0, 0, 0, 0.44);
}
.bannerCaptionss h3{
    font-family: 'Open Sans';
font-style: normal;
font-weight: 600;
font-size: 1.2vw;
line-height: 135%;
color: #222222;
}
.bannerCaptionss ul{
    list-style-type: none;
    padding: 0;
}
.bannerCaptionss ul li{
    margin-bottom: 20px;
    text-align: right;
text-decoration-line: underline;
color: #2B67FF;
font-family: 'Open Sans';
font-style: normal;
font-weight: 500;
font-size: 1.1vw;
}
.bannerCaptionss ul li:last-child{
    margin-bottom: 0;
}
.bannerCaptionss ul li svg{
    width: 26px;
    height: auto;
    margin-left: 10px;
}

.g-recaptcha {
    margin: 10px 0 0;
}

@media only screen and (min-width:2500px){
.container{
    right: 20%;
}    
}

@media only screen and (min-width: 1390px) and (max-width: 1940px){
body{
    height: 100vh;
} 
.calltoaction_block9{
    height: 100vh;
}
.logoLanding{
    right: 30px;
    top: 30px;
}
.container{
    right: 18%;
    max-width: 543px;
}
.cover_content_bx{
padding: 40px 55px;
} 

.cover_content_bx .headingH3{
font-size: 1.4vw;
margin-bottom: 15px;
}
.cover_content_bx p.paragraph_p3 {
    font-size: 16px;
}
.cover_content_bx textarea{
    height: 129px;
font-size: 17px;
}
.cover_content_bx .btn-primary{
padding: 15px 60px;
font-size: 18px;
margin: 15px 0;
}

.bannerCaptionss{
    margin: 15px 0 0px;
    padding: 0px 0 0;
}
.bannerCaptionss h3{
font-size: 22px;
margin: 20px 0 0px;
}
.bannerCaptionss ul li{
    margin-bottom: 8px;
font-size: 18px;
}
.bannerCaptionss ul li svg{
    width: 14px;
    margin-left: 6px;
}    
}



@media only screen and (min-width: 1080px) and (max-width: 1389px){
body{
    height: 100vh;
} 
.calltoaction_block9{
    height: 100vh;
}
.logoLanding{
    right: 20px;
    top: 20px;
}
.container{
    right: 14%;
    max-width: 400px;
}
.cover_content_bx{
padding: 20px 30px;
} 

.cover_content_bx .headingH3{
font-size: 20px;
margin-bottom: 8px;
}
.cover_content_bx p.paragraph_p3 {
    font-size: 16px;
}
.cover_content_bx textarea{
    height: 80px;
font-size: 16px;
}
.cover_content_bx .btn-primary{
padding: 15px 60px;
font-size: 16px;
margin: 5px 0;
}

.bannerCaptionss{
    margin: 15px 0 0px;
    padding: 0px 0 0;
}
.bannerCaptionss h3{
font-size: 18px;
margin: 10px 0 0px;
}
.bannerCaptionss ul li{
    margin-bottom: 6px;
font-size: 16px;
margin-top: 0;
}
.bannerCaptionss ul li svg{
    width: 12px;
    margin-left: 4px;
} 
.g-recaptcha {
    margin: 5px 0 0;
}   
}



@media only screen and (min-width: 768px) and (max-width: 1079px) and (orientation: portrait){
body{
    height: 100vh;
} 
.calltoaction_block9{
    height: 100vh;
}
.logoLanding{
    right: 20px;
    top: 20px;
}
.container{
    right: 0;
    left: 0;
    margin: auto;
    max-width: 450px;
}
.cover_content_bx{
padding: 20px 30px;
} 

.cover_content_bx .headingH3{
font-size: 20px;
margin-bottom: 8px;
}
.cover_content_bx p.paragraph_p3 {
    font-size: 16px;
}
.cover_content_bx textarea{
    height: 80px;
font-size: 16px;
}
.cover_content_bx .btn-primary{
padding: 15px 60px;
font-size: 16px;
margin: 5px 0;
}

.bannerCaptionss{
    margin: 15px 0 0px;
    padding: 0px 0 0;
}
.bannerCaptionss h3{
font-size: 18px;
margin: 10px 0 0px;
}
.bannerCaptionss ul li{
    margin-bottom: 6px;
font-size: 16px;
margin-top: 0;
}
.bannerCaptionss ul li svg{
    width: 12px;
    margin-left: 4px;
} 
.g-recaptcha {
    margin: 5px 0 0;
}   
}

@media only screen and (min-width: 768px) and (max-width: 840px) and (orientation: portrait){
 body{
    height: auto!important;
    overflow: visible!important;
} 
.calltoaction_block9{
    height: 130vh;
    overflow: visible!important;
}  
.container {
    top: 35vh;
    bottom: unset;
} 
.profile-pic img{
    object-position: center;
}
}

@media only screen and (max-width: 1080px) and (orientation: landscape){
body{
    height: 100vh;
} 
.calltoaction_block9{
    height: 100vh;
}
.logoLanding{
    right: 20px;
    top: 20px;
}
.container{
    right: 18px;
    margin: auto;
    max-width: 330px;
}
.cover_content_bx{
padding: 20px 20px;
} 

.cover_content_bx .headingH3{
font-size: 20px;
margin-bottom: 8px;
}
.cover_content_bx p.paragraph_p3 {
    font-size: 16px;
}
.cover_content_bx textarea{
    height: 80px;
font-size: 16px;
}
.cover_content_bx .btn-primary{
padding: 15px 60px;
font-size: 16px;
margin: 5px 0;
}

.bannerCaptionss{
    margin: 15px 0 0px;
    padding: 0px 0 0;
}
.bannerCaptionss h3{
font-size: 18px;
margin: 10px 0 0px;
}
.bannerCaptionss ul{
    margin: 10px 0 0;
}
.bannerCaptionss ul li{
    margin-bottom: 6px;
font-size: 16px;
margin-top: 0;
}
.bannerCaptionss ul li svg{
    width: 12px;
    margin-left: 4px;
} 
.g-recaptcha {
    margin: 5px 0 0;
}   
}


/**mobile landscape**/
@media only screen and (max-width: 980px) and (orientation: landscape){
body{
    height: 100vh;
} 
.calltoaction_block9{
    height: 100vh;
}
.logoLanding{
    right: 20px;
    top: 20px;
    display: none;
}
.container{
    right: 50px;
    margin: auto;
    max-width: 300px;
}
.cover_content_bx{
padding: 10px 15px;
} 

.cover_content_bx .headingH3{
font-size: 16px;
margin-bottom: 5px;
}
.cover_content_bx p.paragraph_p3 {
    font-size: 14px;
}
.cover_content_bx textarea{
    height: 50px;
font-size: 14px;
}
.cover_content_bx .btn-primary{
padding: 8px 30px;
font-size: 14px;
margin:-20px 0px 0;
}

.bannerCaptionss{
    margin: 15px 0 0px;
    padding: 0px 0 0;
}
.bannerCaptionss h3{
font-size: 16px;
margin: 10px 0 0px;
}
.bannerCaptionss ul{
    margin: 5px 0 0;
}
.bannerCaptionss ul li{
    margin-bottom: 3px;
font-size: 13px;
margin-top: 0;
}
.bannerCaptionss ul li svg{
    width: 9px;
    margin-left: 2px;
} 
.g-recaptcha {
    margin: 5px 0 0;
}   
}
/**small mobile landscape**/
@media only screen and (max-width: 700px) and (orientation: landscape){
body{
    height: 100vh;
} 
.calltoaction_block9{
    height: 100vh;
}
.logoLanding{
    right: 20px;
    top: 20px;
    display: none;
}
.container{
    right: 50px;
    margin: auto;
    max-width: 250px;
    display: block;
    top: 5%;
}
.cover_content_bx{
padding: 10px 15px;
} 

.cover_content_bx .headingH3{
font-size: 14px;
margin-bottom: 2px;
}
.cover_content_bx p.paragraph_p3 {
    font-size: 12px;
}
.cover_content_bx textarea{
    height: 30px;
font-size: 12px;
}
.cover_content_bx .btn-primary{
padding: 5px 20px;
font-size: 12px;
margin:-20px 0px 0;
}
.bannerCaptionss{
    margin: 5px 0 0px;
    padding: 0px 0 0;
}
.bannerCaptionss h3{
font-size: 14px;
margin: 6px 0 0px;
}
.bannerCaptionss ul{
    margin: 5px 0 0;
}
.bannerCaptionss ul li{
    margin-bottom: 2px;
font-size: 11px;
margin-top: 0;
}
.bannerCaptionss ul li svg{
    width: 6px;
    margin-left: 2px;
} 
.g-recaptcha {
    margin: 5px 0 0;
}
.g-recaptcha {
    position: relative;
    left: 61px;
}   
}


@media only screen and (max-width: 580px) and (orientation: portrait){
body{
    height: auto;
    overflow: visible;
} 
.calltoaction_block9{
    overflow: visible;
    height: auto;
}
.logoLanding{
    right: 10px;
    top: 10px;
}
.logoLanding img{
    width: 90px;
}
.container{
    right: 0;
    left: 0;
    margin: auto;
    max-width: 330px;
}
.cover_content_bx{
padding: 20px 20px;
} 

.cover_content_bx .headingH3{
font-size: 20px;
margin-bottom: 8px;
}
.cover_content_bx p.paragraph_p3 {
    font-size: 16px;
}
.cover_content_bx textarea{
    height: 80px;
font-size: 16px;
}
.cover_content_bx .btn-primary{
padding: 10px 40px;
font-size: 16px;
margin: 5px 0;
}

.bannerCaptionss{
    margin: 15px 0 0px;
    padding: 0px 0 0;
}
.bannerCaptionss h3{
font-size: 18px;
margin: 10px 0 0px;
}
.bannerCaptionss ul{
    margin: 10px 0 0;
}
.bannerCaptionss ul li{
    margin-bottom: 6px;
font-size: 16px;
margin-top: 0;
}
.bannerCaptionss ul li svg{
    width: 12px;
    margin-left: 4px;
} 
.g-recaptcha {
    margin: 5px 0 0;
} 
.profile-pic img{
    object-position: top;
}  
}

@media only screen and (max-width: 580px) and (orientation: portrait){
.container{
    right: 0;
    left: 0;
    margin: auto;
    max-width: 280px;
    display: block;
    margin-top: 180px;
}
.cover_content_bx{
padding: 20px 20px;
} 

.cover_content_bx .headingH3{
font-size: 18px;
margin-bottom: 8px;
}
.cover_content_bx p.paragraph_p3 {
    font-size: 14px;
}
.cover_content_bx textarea{
    height: 60px;
font-size: 14px;
}
.cover_content_bx .btn-primary{
padding: 10px 40px;
font-size: 14px;
margin: 3px 0;
}

.bannerCaptionss{
    margin: 10px 0 0px;
    padding: 0px 0 0;
}
.bannerCaptionss h3{
font-size: 16px;
margin: 8px 0 0px;
}
.bannerCaptionss ul{
    margin: 10px 0 0;
}
.bannerCaptionss ul li{
    margin-bottom: 4px;
font-size: 12px;
margin-top: 0;
}
.bannerCaptionss ul li svg{
    width: 12px;
    margin-left: 4px;
} 
.g-recaptcha {
    margin: 5px 0 0;
} 
.profile-pic img{
    object-position: top;
} 
.g-recaptcha {
    position: relative;
    left: 50px;
} 
}




@media only screen and (min-width: 360px) and (max-width: 767px){
.g-recaptcha {
    transform: scale(0.80)!important;
    -webkit-transform: scale(0.80)!important;
    transform-origin: 0 0!important;
    -webkit-transform-origin: 0 0!important;
}
}
@media only screen and (min-width: 360px) and (max-width: 999px) and (orientation: landscape){
.g-recaptcha {
   transform: scale(0.68)!important;
    -webkit-transform: scale(0.68)!important;
    transform-origin: 0 0!important;
    -webkit-transform-origin: 0 0!important;
}
}
@media only screen and (max-width: 359px){
.g-recaptcha {
    transform: scale(0.68)!important;
    -webkit-transform: scale(0.68)!important;
    transform-origin: 0 0!important;
    -webkit-transform-origin: 0 0!important;
}
}

section{
    padding: 0px !important;
}

.error{
    color: red;
}



section.thankYouPage {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    z-index: 999;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
section.thankYouPage:before{
    background: rgba(255, 255, 255, 0.89);
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
} 
.wrap_thanks {
    position: relative;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
}
.thanks_container {
    width: 100%;
    max-width: 1200px;
    margin: auto;
    height: 816px;
    position: relative;
    border-radius: 30px;
}
.thanks_container picture.profile-pic {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
}
.thanks_container picture.profile-pic img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 30px;
}
span.closeThanks {
    position: absolute;
    top: 27px;
    left: 22px;
    z-index: 999;
    cursor: pointer;
    z-index: 99999;
}
.thanks_container .logoLanding img{
    width: 158px;
}
.content_bx_thanks {
    position: absolute;
    z-index: 999;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding-top: 60px;
}
.content_bx_thanks h1{
font-weight: 600;
font-size: 80px;
line-height: 135%;
text-transform: uppercase;
color: #FFFFFF;
font-family: 'Open Sans';
margin: 0 0 20px;
}

.content_bx_thanks p{
  font-family: 'Open Sans';
font-style: normal;
font-weight: 600;
font-size: 34px;
line-height: 135%;
text-align: center;
color: #FFFFFF; 
margin: 0 0 60px; 
}
.content_bx_thanks .btn-primary{
    background: #FFFF00;
    border-radius: 10px;
    padding: 29px 83px;
    text-align: center;
text-transform: capitalize;

color: #1704F9;
font-family: 'Open Sans';
font-style: normal;
font-weight: 700;
font-size: 32px;
text-decoration: none;
transition: all 0.6s;
}

.content_bx_thanks .btn-primary:hover{
  background-color: #000;
  color: #fff;  
}

@media only screen and (min-width: 1600px) and (max-width: 1940px){
.thanks_container {
    max-width: 980px;
    height: 650px;
    border-radius: 30px;
}
.thanks_container picture.profile-pic img{
    border-radius: 30px;
}
span.closeThanks {
    top: 20px;
    left: 18px;
}
.thanks_container .logoLanding{
    top: 20px;
}
.thanks_container .logoLanding img{
    width: 130px;
}
.content_bx_thanks {
    padding-top: 40px;
}
.content_bx_thanks h1{
font-size: 80px;
margin: 0 0 20px;
}
.content_bx_thanks p{
 font-size: 28px;
margin: 0 0 40px; 
}
.content_bx_thanks .btn-primary{
    padding: 20px 53px;
font-size: 22px;
}
}

@media only screen and (min-width: 1000px) and (max-width: 1599px){
.thanks_container {
    max-width: 800px;
    height: 500px;
    border-radius: 20px;
}
.thanks_container picture.profile-pic img{
    border-radius: 20px;
}
span.closeThanks {
    top: 20px;
    left: 18px;
}
.thanks_container .logoLanding{
    top: 20px;
}
.thanks_container .logoLanding img{
    width: 110px;
}
.content_bx_thanks {
    padding-top: 40px;
}
.content_bx_thanks h1{
font-size: 60px;
margin: 0 0 20px;
}
.content_bx_thanks p{
 font-size: 22px;
margin: 0 0 40px; 
}
.content_bx_thanks .btn-primary{
    padding: 16px 43px;
font-size: 18px;
}
}


@media only screen and (min-width: 768px) and (max-width: 999px){
.thanks_container {
    max-width: 600px;
    height: 400px;
    border-radius: 20px;
}
.thanks_container picture.profile-pic img{
    border-radius: 20px;
}
span.closeThanks {
    top: 18px;
    left: 18px;
}
.thanks_container .logoLanding{
    top: 18px;
}
.thanks_container .logoLanding img{
    width: 110px;
}
.content_bx_thanks {
    padding-top: 40px;
}
.content_bx_thanks h1{
font-size: 50px;
margin: 0 0 20px;
}
.content_bx_thanks p{
 font-size: 22px;
margin: 0 0 30px; 
}
.content_bx_thanks .btn-primary{
    padding: 16px 23px;
font-size: 16px;
}
}


@media only screen and (max-width: 580px) and (orientation: portrait){
.thanks_container {
    max-width: 100%;
    height: 100vh;
    border-radius: 30px;
}
.wrap_thanks{
        padding: 34px 0;
        border-radius: 30px;
}
.thanks_container picture.profile-pic img{
    border-radius: 0px;
    object-fit: contain;
}
span.closeThanks {
    top: 18px;
    left: 18px;
}
span.closeThanks svg{
    width: 20px;
}
.thanks_container .logoLanding{
    top: 18px;
}
.thanks_container .logoLanding img{
    width: 110px;
}

.content_bx_thanks {
    padding-top: 85px;
}
.content_bx_thanks h1{
font-size: 40px;
margin: 0 0 20px;
}
.content_bx_thanks p{
 font-size: 22px;
margin: 0 0 30px; 
}
.content_bx_thanks .btn-primary{
    padding: 16px 38px;
font-size: 16px;
}
}
@media only screen and (max-width: 390px) and (orientation: portrait){
.thanks_container picture.profile-pic img{
    border-radius: 0px;
    object-fit: cover;
}
}

@media only screen and (max-width: 980px) and (orientation: landscape){
.thanks_container {
    max-width: 100%;
    height: 100vh;
    border-radius: 30px;
        top: 5%;
}
.thanks_container picture.profile-pic img{
    border-radius: 0px;
    object-fit: contain;
    object-position: top;
}
span.closeThanks {
    top: 18px;
    left: 18px;
}
span.closeThanks svg{
    width: 16px;
}
.thanks_container .logoLanding{
    top: 18px;
    display: block;
}
.thanks_container .logoLanding img{
    width: 110px;
}

.content_bx_thanks {
    padding-top: 25px;
}
.content_bx_thanks h1{
font-size: 35px;
margin: 0 0 15px;
}
.content_bx_thanks p{
 font-size: 18px;
margin: 0 0 25px; 
}
.content_bx_thanks .btn-primary{
    padding: 12px 28px;
font-size: 14px;
}
}

@media only screen and (max-width: 700px) and (orientation: landscape){

span.closeThanks svg{
    width: 14px;
}
.thanks_container .logoLanding{
    top: 18px;
    display: block;
}
.thanks_container .logoLanding img{
    width: 80px;
}

.content_bx_thanks {
    padding-top: 15px;
}
.content_bx_thanks h1{
font-size: 25px;
margin: 0 0 5px;
}
.content_bx_thanks p{
 font-size: 16px;
margin: 0 0 15px; 
}
.content_bx_thanks .btn-primary{
    padding: 10px 20px;
font-size: 12px;
}
}